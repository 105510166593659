import * as Sentry from "@sentry/react";
import axios from "axios";
import axiosRetry from "axios-retry";
import {
  BookingInfoPayload,
  BookingInfoResponse,
  CompleteIntakePayload,
  CompleteIntakeResponse,
  CreateAppointmentResponse,
  InsuranceValidationPayload,
  InsuranceValidationResponse,
  PricePayload,
  PriceResponse,
  ProviderAppointmentPayload,
  ProviderAvailabilitiesResponse,
  ProviderDetailResponse,
  SubmitBookingEmailPayload,
  SubmitBookingEmailResponse,
} from "./Model";

export const URL = process.env.REACT_APP_FAY_SERVICE_API_BASE_PATH;

const axiosClient = axios.create({
  headers:
    process.env.NODE_ENV === "development"
      ? {
          "ngrok-skip-browser-warning": `TRUE`,
        }
      : {},
});

const retryClient = axios.create({
  headers:
    process.env.NODE_ENV === "development"
      ? {
          "ngrok-skip-browser-warning": `TRUE`,
        }
      : {},
});

axiosRetry(retryClient, {
  retries: 5,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    // Check if the request was a GET request
    const isGetRequest = error && error.config && error.config.method === "get";

    // Retry only for GET requests and either status code 500 or network errors
    return (isGetRequest &&
      (error.response === undefined ||
        error.code === "ECONNABORTED")) as boolean;
  },
});

const Api = {
  validateInsurance: async (
    payload: InsuranceValidationPayload,
  ): Promise<InsuranceValidationResponse> =>
    (await axiosClient.post(`${URL}/insurance-validation`, payload)).data,
  getPrice: async (payload: PricePayload): Promise<PriceResponse> =>
    (await axiosClient.post(`${URL}/price`, payload)).data,
  getProviderDetail: async (
    providerId: string,
  ): Promise<ProviderDetailResponse> => {
    try {
      const response = await retryClient.get(`${URL}/provider/${providerId}`);
      return response.data;
    } catch (e) {
      Sentry.captureMessage(`Error while running getProviderDetail: ${e}`, {
        level: "error",
        extra: {
          apiCall: "getProviderDetail",
        },
      });
      throw e;
    }
  },
  getProviderAvailabilities: async (
    providerId: string,
    start: Date,
    end: Date,
    impressionToken?: string,
  ): Promise<ProviderAvailabilitiesResponse> => {
    // start=${start.toISOString()}&end=${end.toISOString()}${impressionToken ? `&pcrit=${impressionToken}` : ""}
    const params = new URLSearchParams({
      start: start.toISOString(),
      end: end.toISOString(),
      ...(impressionToken ? { pcrit: impressionToken } : {}),
    });
    const response = await retryClient.get(
      `${URL}/provider/${providerId}/availability`,
      { params },
    );

    return response.data;
  },
  submitBookingEmail: async (
    payload: SubmitBookingEmailPayload,
  ): Promise<SubmitBookingEmailResponse> =>
    (await axiosClient.post(`${URL}/booking/submit-email`, payload)).data,
  createAppointment: async (
    data: ProviderAppointmentPayload,
  ): Promise<CreateAppointmentResponse> =>
    (await axiosClient.post(`${URL}/appointment`, data)).data,
  getBookingInfo: async (
    payload: BookingInfoPayload,
  ): Promise<BookingInfoResponse> =>
    (await axiosClient.post(`${URL}/booking-info`, payload)).data,
  completeIntake: async (
    payload: CompleteIntakePayload,
  ): Promise<CompleteIntakeResponse> =>
    (await axiosClient.post(`${URL}/intake-completion`, payload)).data,
};

export default Api;

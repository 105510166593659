/* tslint:disable */
/* eslint-disable */
/**
 * Provider API
 * Fay Provider API
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface CreateAdminUserPayload
 */
export interface CreateAdminUserPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserPayload
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateProviderUserPayload
 */
export interface CreateProviderUserPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderUserPayload
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface GenerateMealPlanPayload
 */
export interface GenerateMealPlanPayload {
    /**
     * 
     * @type {string}
     * @memberof GenerateMealPlanPayload
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateMealPlanPayload
     */
    'conditions': Array<string>;
}
/**
 * 
 * @export
 * @interface GenerateMealPlanResponse
 */
export interface GenerateMealPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateMealPlanResponse
     */
    'meal_plan_id': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HealthResponse
 */
export interface HealthResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface InternalGenerateMealPlanPayload
 */
export interface InternalGenerateMealPlanPayload {
    /**
     * 
     * @type {string}
     * @memberof InternalGenerateMealPlanPayload
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InternalGenerateMealPlanPayload
     */
    'conditions': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InternalGenerateMealPlanPayload
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface InternalSendMealPlanPayload
 */
export interface InternalSendMealPlanPayload {
    /**
     * 
     * @type {string}
     * @memberof InternalSendMealPlanPayload
     */
    'patient_email': string;
    /**
     * 
     * @type {string}
     * @memberof InternalSendMealPlanPayload
     */
    'provider_email': string;
    /**
     * 
     * @type {string}
     * @memberof InternalSendMealPlanPayload
     */
    'provider_name': string;
}
/**
 * 
 * @export
 * @interface LocationInner
 */
export interface LocationInner {
}
/**
 * 
 * @export
 * @interface Meal
 */
export interface Meal {
    /**
     * 
     * @type {string}
     * @memberof Meal
     */
    'meal_name': string;
    /**
     * 
     * @type {Recipe}
     * @memberof Meal
     */
    'recipe': Recipe;
}
/**
 * 
 * @export
 * @interface MealPlanResponse
 */
export interface MealPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof MealPlanResponse
     */
    'meal_plan_id': string;
    /**
     * 
     * @type {Array<Meal>}
     * @memberof MealPlanResponse
     */
    'meals': Array<Meal>;
}
/**
 * 
 * @export
 * @interface PracticeMetricsResponse
 */
export interface PracticeMetricsResponse {
    /**
     * 
     * @type {string}
     * @memberof PracticeMetricsResponse
     */
    'as_of_date': string;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'weekly_retention'?: number;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'avg_appointment_length_minutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'avg_rating'?: number;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'recurring_weekly_availability_hours': number;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'practice_optimization_score'?: number;
}
/**
 * 
 * @export
 * @interface ProviderDetails
 */
export interface ProviderDetails {
    /**
     * 
     * @type {string}
     * @memberof ProviderDetails
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetails
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetails
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDetails
     */
    'invited': boolean;
}
/**
 * 
 * @export
 * @interface ProviderPayoutLineItem
 */
export interface ProviderPayoutLineItem {
    /**
     * 
     * @type {string}
     * @memberof ProviderPayoutLineItem
     */
    'appointment_date': string;
    /**
     * 
     * @type {number}
     * @memberof ProviderPayoutLineItem
     */
    'appointment_duration_mins': number;
    /**
     * 
     * @type {number}
     * @memberof ProviderPayoutLineItem
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof ProviderPayoutLineItem
     */
    'payment_source': string;
}
/**
 * 
 * @export
 * @interface ProviderPayoutResponse
 */
export interface ProviderPayoutResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderPayoutResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderPayoutResponse
     */
    'period_start': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderPayoutResponse
     */
    'period_end': string;
    /**
     * 
     * @type {Array<ProviderPayoutLineItem>}
     * @memberof ProviderPayoutResponse
     */
    'line_items': Array<ProviderPayoutLineItem>;
}
/**
 * 
 * @export
 * @interface ProviderProfile
 */
export interface ProviderProfile {
    /**
     * 
     * @type {string}
     * @memberof ProviderProfile
     */
    'provider_profile_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderProfile
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderProfile
     */
    'timezone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderProfile
     */
    'is_migrated'?: boolean;
}
/**
 * 
 * @export
 * @interface Recipe
 */
export interface Recipe {
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Recipe
     */
    'ingredients': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Recipe
     */
    'steps': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    'image_url': string;
}
/**
 * 
 * @export
 * @interface ResendUserInvitePayload
 */
export interface ResendUserInvitePayload {
    /**
     * 
     * @type {string}
     * @memberof ResendUserInvitePayload
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ResendUserInviteResponse
 */
export interface ResendUserInviteResponse {
    /**
     * 
     * @type {string}
     * @memberof ResendUserInviteResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface SendMealPlanPayload
 */
export interface SendMealPlanPayload {
    /**
     * 
     * @type {string}
     * @memberof SendMealPlanPayload
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface SendMealPlanResponse
 */
export interface SendMealPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof SendMealPlanResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface SyncHealthieEmailsPayload
 */
export interface SyncHealthieEmailsPayload {
    /**
     * 
     * @type {boolean}
     * @memberof SyncHealthieEmailsPayload
     */
    'dry_run'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateMealPlanPayload
 */
export interface UpdateMealPlanPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateMealPlanPayload
     */
    'meal_plan_id': string;
    /**
     * 
     * @type {Array<Meal>}
     * @memberof UpdateMealPlanPayload
     */
    'meals': Array<Meal>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'role': string;
    /**
     * 
     * @type {ProviderProfile}
     * @memberof User
     */
    'provider_profile': ProviderProfile;
}
/**
 * 
 * @export
 * @interface UserAuthenticationInfo
 */
export interface UserAuthenticationInfo {
    /**
     * 
     * @type {boolean}
     * @memberof UserAuthenticationInfo
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAuthenticationInfo
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UserAuthenticationInfo
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface UserCreatedResponse
 */
export interface UserCreatedResponse {
    /**
     * 
     * @type {string}
     * @memberof UserCreatedResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface UserDeactivatedResponse
 */
export interface UserDeactivatedResponse {
    /**
     * 
     * @type {string}
     * @memberof UserDeactivatedResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<LocationInner>}
     * @memberof ValidationError
     */
    'loc': Array<LocationInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Admin Deactivate Provider
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost: async (healthieProviderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthieProviderId' is not null or undefined
            assertParamExists('adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost', 'healthieProviderId', healthieProviderId)
            const localVarPath = `/admin/provider/{healthie_provider_id}/deactivate`
                .replace(`{${"healthie_provider_id"}}`, encodeURIComponent(String(healthieProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin Get Provider Practice Metrics
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet: async (healthieProviderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthieProviderId' is not null or undefined
            assertParamExists('adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet', 'healthieProviderId', healthieProviderId)
            const localVarPath = `/admin/provider/{healthie_provider_id}/practice-metrics`
                .replace(`{${"healthie_provider_id"}}`, encodeURIComponent(String(healthieProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Admin Deactivate Provider
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDeactivatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Admin Get Provider Practice Metrics
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Admin Deactivate Provider
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId: string, options?: RawAxiosRequestConfig): AxiosPromise<UserDeactivatedResponse> {
            return localVarFp.adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin Get Provider Practice Metrics
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId: string, options?: RawAxiosRequestConfig): AxiosPromise<PracticeMetricsResponse> {
            return localVarFp.adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Admin Deactivate Provider
     * @param {string} healthieProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin Get Provider Practice Metrics
     * @param {string} healthieProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Admin Sync Healthie Emails
         * @param {SyncHealthieEmailsPayload} syncHealthieEmailsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSyncHealthieEmailsAdminSyncHealthieEmailsPost: async (syncHealthieEmailsPayload: SyncHealthieEmailsPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'syncHealthieEmailsPayload' is not null or undefined
            assertParamExists('adminSyncHealthieEmailsAdminSyncHealthieEmailsPost', 'syncHealthieEmailsPayload', syncHealthieEmailsPayload)
            const localVarPath = `/admin/sync-healthie-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncHealthieEmailsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Admin User
         * @param {CreateAdminUserPayload} createAdminUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminUserAdminUsersAdminPost: async (createAdminUserPayload: CreateAdminUserPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdminUserPayload' is not null or undefined
            assertParamExists('createAdminUserAdminUsersAdminPost', 'createAdminUserPayload', createAdminUserPayload)
            const localVarPath = `/admin/users/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdminUserPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Provider User
         * @param {CreateProviderUserPayload} createProviderUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderUserAdminUsersProviderPost: async (createProviderUserPayload: CreateProviderUserPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderUserPayload' is not null or undefined
            assertParamExists('createProviderUserAdminUsersProviderPost', 'createProviderUserPayload', createProviderUserPayload)
            const localVarPath = `/admin/users/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderUserPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCurrentUserUsersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Payouts For Provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPayoutsForProviderProviderPayoutsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provider/payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProvidersAdminProvidersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Meal Plan
         * @param {GenerateMealPlanPayload} generateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMealPlanMealplanPost: async (generateMealPlanPayload: GenerateMealPlanPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateMealPlanPayload' is not null or undefined
            assertParamExists('generateMealPlanMealplanPost', 'generateMealPlanPayload', generateMealPlanPayload)
            const localVarPath = `/mealplan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateMealPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealPlanInternalMealplanMealPlanIdGet: async (mealPlanId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('getMealPlanInternalMealplanMealPlanIdGet', 'mealPlanId', mealPlanId)
            const localVarPath = `/internal/mealplan/{meal_plan_id}`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealPlanMealplanMealPlanIdGet: async (mealPlanId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('getMealPlanMealplanMealPlanIdGet', 'mealPlanId', mealPlanId)
            const localVarPath = `/mealplan/{meal_plan_id}`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Practice Metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPracticeMetricsProviderPracticeMetricsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provider/practice-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Authentication Info
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAuthenticationInfoUsersAuthenticationInfoGet: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getUserAuthenticationInfoUsersAuthenticationInfoGet', 'email', email)
            const localVarPath = `/users/authentication-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckHealthGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Generate Meal Plan
         * @param {InternalGenerateMealPlanPayload} internalGenerateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGenerateMealPlanInternalMealplanPost: async (internalGenerateMealPlanPayload: InternalGenerateMealPlanPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalGenerateMealPlanPayload' is not null or undefined
            assertParamExists('internalGenerateMealPlanInternalMealplanPost', 'internalGenerateMealPlanPayload', internalGenerateMealPlanPayload)
            const localVarPath = `/internal/mealplan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalGenerateMealPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Send Meal Plan
         * @param {string} mealPlanId 
         * @param {InternalSendMealPlanPayload} internalSendMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalSendMealPlanInternalMealplanMealPlanIdSendPost: async (mealPlanId: string, internalSendMealPlanPayload: InternalSendMealPlanPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('internalSendMealPlanInternalMealplanMealPlanIdSendPost', 'mealPlanId', mealPlanId)
            // verify required parameter 'internalSendMealPlanPayload' is not null or undefined
            assertParamExists('internalSendMealPlanInternalMealplanMealPlanIdSendPost', 'internalSendMealPlanPayload', internalSendMealPlanPayload)
            const localVarPath = `/internal/mealplan/{meal_plan_id}/send`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalSendMealPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Update Meal Plan
         * @param {string} mealPlanId 
         * @param {UpdateMealPlanPayload} updateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateMealPlanInternalMealplanMealPlanIdPut: async (mealPlanId: string, updateMealPlanPayload: UpdateMealPlanPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('internalUpdateMealPlanInternalMealplanMealPlanIdPut', 'mealPlanId', mealPlanId)
            // verify required parameter 'updateMealPlanPayload' is not null or undefined
            assertParamExists('internalUpdateMealPlanInternalMealplanMealPlanIdPut', 'updateMealPlanPayload', updateMealPlanPayload)
            const localVarPath = `/internal/mealplan/{meal_plan_id}`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMealPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend User Invite
         * @param {ResendUserInvitePayload} resendUserInvitePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInviteAdminUsersResendInvitePost: async (resendUserInvitePayload: ResendUserInvitePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendUserInvitePayload' is not null or undefined
            assertParamExists('resendUserInviteAdminUsersResendInvitePost', 'resendUserInvitePayload', resendUserInvitePayload)
            const localVarPath = `/admin/users/resend-invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendUserInvitePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Meal Plan
         * @param {string} mealPlanId 
         * @param {SendMealPlanPayload} sendMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMealPlanMealplanMealPlanIdSendPost: async (mealPlanId: string, sendMealPlanPayload: SendMealPlanPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('sendMealPlanMealplanMealPlanIdSendPost', 'mealPlanId', mealPlanId)
            // verify required parameter 'sendMealPlanPayload' is not null or undefined
            assertParamExists('sendMealPlanMealplanMealPlanIdSendPost', 'sendMealPlanPayload', sendMealPlanPayload)
            const localVarPath = `/mealplan/{meal_plan_id}/send`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMealPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Meal Plan
         * @param {string} mealPlanId 
         * @param {UpdateMealPlanPayload} updateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMealPlanMealplanMealPlanIdPut: async (mealPlanId: string, updateMealPlanPayload: UpdateMealPlanPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('updateMealPlanMealplanMealPlanIdPut', 'mealPlanId', mealPlanId)
            // verify required parameter 'updateMealPlanPayload' is not null or undefined
            assertParamExists('updateMealPlanMealplanMealPlanIdPut', 'updateMealPlanPayload', updateMealPlanPayload)
            const localVarPath = `/mealplan/{meal_plan_id}`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMealPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Admin Sync Healthie Emails
         * @param {SyncHealthieEmailsPayload} syncHealthieEmailsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSyncHealthieEmailsAdminSyncHealthieEmailsPost(syncHealthieEmailsPayload: SyncHealthieEmailsPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSyncHealthieEmailsAdminSyncHealthieEmailsPost(syncHealthieEmailsPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.adminSyncHealthieEmailsAdminSyncHealthieEmailsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Admin User
         * @param {CreateAdminUserPayload} createAdminUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminUserAdminUsersAdminPost(createAdminUserPayload: CreateAdminUserPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminUserAdminUsersAdminPost(createAdminUserPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createAdminUserAdminUsersAdminPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Provider User
         * @param {CreateProviderUserPayload} createProviderUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProviderUserAdminUsersProviderPost(createProviderUserPayload: CreateProviderUserPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProviderUserAdminUsersProviderPost(createProviderUserPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createProviderUserAdminUsersProviderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchCurrentUserUsersMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchCurrentUserUsersMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fetchCurrentUserUsersMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch Payouts For Provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPayoutsForProviderProviderPayoutsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderPayoutResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPayoutsForProviderProviderPayoutsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fetchPayoutsForProviderProviderPayoutsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchProvidersAdminProvidersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProvidersAdminProvidersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fetchProvidersAdminProvidersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generate Meal Plan
         * @param {GenerateMealPlanPayload} generateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMealPlanMealplanPost(generateMealPlanPayload: GenerateMealPlanPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateMealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateMealPlanMealplanPost(generateMealPlanPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.generateMealPlanMealplanPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealPlanInternalMealplanMealPlanIdGet(mealPlanId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealPlanInternalMealplanMealPlanIdGet(mealPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMealPlanInternalMealplanMealPlanIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealPlanMealplanMealPlanIdGet(mealPlanId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealPlanMealplanMealPlanIdGet(mealPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMealPlanMealplanMealPlanIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Practice Metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderPracticeMetricsProviderPracticeMetricsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderPracticeMetricsProviderPracticeMetricsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getProviderPracticeMetricsProviderPracticeMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get User Authentication Info
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAuthenticationInfoUsersAuthenticationInfoGet(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthenticationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAuthenticationInfoUsersAuthenticationInfoGet(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getUserAuthenticationInfoUsersAuthenticationInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckHealthGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckHealthGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.healthCheckHealthGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Generate Meal Plan
         * @param {InternalGenerateMealPlanPayload} internalGenerateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGenerateMealPlanInternalMealplanPost(internalGenerateMealPlanPayload: InternalGenerateMealPlanPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateMealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGenerateMealPlanInternalMealplanPost(internalGenerateMealPlanPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.internalGenerateMealPlanInternalMealplanPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Send Meal Plan
         * @param {string} mealPlanId 
         * @param {InternalSendMealPlanPayload} internalSendMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalSendMealPlanInternalMealplanMealPlanIdSendPost(mealPlanId: string, internalSendMealPlanPayload: InternalSendMealPlanPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendMealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalSendMealPlanInternalMealplanMealPlanIdSendPost(mealPlanId, internalSendMealPlanPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.internalSendMealPlanInternalMealplanMealPlanIdSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Update Meal Plan
         * @param {string} mealPlanId 
         * @param {UpdateMealPlanPayload} updateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateMealPlanInternalMealplanMealPlanIdPut(mealPlanId: string, updateMealPlanPayload: UpdateMealPlanPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateMealPlanInternalMealplanMealPlanIdPut(mealPlanId, updateMealPlanPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.internalUpdateMealPlanInternalMealplanMealPlanIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resend User Invite
         * @param {ResendUserInvitePayload} resendUserInvitePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendUserInviteAdminUsersResendInvitePost(resendUserInvitePayload: ResendUserInvitePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResendUserInviteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendUserInviteAdminUsersResendInvitePost(resendUserInvitePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.resendUserInviteAdminUsersResendInvitePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send Meal Plan
         * @param {string} mealPlanId 
         * @param {SendMealPlanPayload} sendMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMealPlanMealplanMealPlanIdSendPost(mealPlanId: string, sendMealPlanPayload: SendMealPlanPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendMealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMealPlanMealplanMealPlanIdSendPost(mealPlanId, sendMealPlanPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.sendMealPlanMealplanMealPlanIdSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Meal Plan
         * @param {string} mealPlanId 
         * @param {UpdateMealPlanPayload} updateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMealPlanMealplanMealPlanIdPut(mealPlanId: string, updateMealPlanPayload: UpdateMealPlanPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMealPlanMealplanMealPlanIdPut(mealPlanId, updateMealPlanPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateMealPlanMealplanMealPlanIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Admin Sync Healthie Emails
         * @param {SyncHealthieEmailsPayload} syncHealthieEmailsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSyncHealthieEmailsAdminSyncHealthieEmailsPost(syncHealthieEmailsPayload: SyncHealthieEmailsPayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.adminSyncHealthieEmailsAdminSyncHealthieEmailsPost(syncHealthieEmailsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Admin User
         * @param {CreateAdminUserPayload} createAdminUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminUserAdminUsersAdminPost(createAdminUserPayload: CreateAdminUserPayload, options?: RawAxiosRequestConfig): AxiosPromise<UserCreatedResponse> {
            return localVarFp.createAdminUserAdminUsersAdminPost(createAdminUserPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Provider User
         * @param {CreateProviderUserPayload} createProviderUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderUserAdminUsersProviderPost(createProviderUserPayload: CreateProviderUserPayload, options?: RawAxiosRequestConfig): AxiosPromise<UserCreatedResponse> {
            return localVarFp.createProviderUserAdminUsersProviderPost(createProviderUserPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCurrentUserUsersMeGet(options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.fetchCurrentUserUsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Payouts For Provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPayoutsForProviderProviderPayoutsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<ProviderPayoutResponse>> {
            return localVarFp.fetchPayoutsForProviderProviderPayoutsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProvidersAdminProvidersGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<ProviderDetails>> {
            return localVarFp.fetchProvidersAdminProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Meal Plan
         * @param {GenerateMealPlanPayload} generateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMealPlanMealplanPost(generateMealPlanPayload: GenerateMealPlanPayload, options?: RawAxiosRequestConfig): AxiosPromise<GenerateMealPlanResponse> {
            return localVarFp.generateMealPlanMealplanPost(generateMealPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealPlanInternalMealplanMealPlanIdGet(mealPlanId: string, options?: RawAxiosRequestConfig): AxiosPromise<MealPlanResponse> {
            return localVarFp.getMealPlanInternalMealplanMealPlanIdGet(mealPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealPlanMealplanMealPlanIdGet(mealPlanId: string, options?: RawAxiosRequestConfig): AxiosPromise<MealPlanResponse> {
            return localVarFp.getMealPlanMealplanMealPlanIdGet(mealPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Practice Metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPracticeMetricsProviderPracticeMetricsGet(options?: RawAxiosRequestConfig): AxiosPromise<PracticeMetricsResponse> {
            return localVarFp.getProviderPracticeMetricsProviderPracticeMetricsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Authentication Info
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAuthenticationInfoUsersAuthenticationInfoGet(email: string, options?: RawAxiosRequestConfig): AxiosPromise<UserAuthenticationInfo> {
            return localVarFp.getUserAuthenticationInfoUsersAuthenticationInfoGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckHealthGet(options?: RawAxiosRequestConfig): AxiosPromise<HealthResponse> {
            return localVarFp.healthCheckHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Generate Meal Plan
         * @param {InternalGenerateMealPlanPayload} internalGenerateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGenerateMealPlanInternalMealplanPost(internalGenerateMealPlanPayload: InternalGenerateMealPlanPayload, options?: RawAxiosRequestConfig): AxiosPromise<GenerateMealPlanResponse> {
            return localVarFp.internalGenerateMealPlanInternalMealplanPost(internalGenerateMealPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Send Meal Plan
         * @param {string} mealPlanId 
         * @param {InternalSendMealPlanPayload} internalSendMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalSendMealPlanInternalMealplanMealPlanIdSendPost(mealPlanId: string, internalSendMealPlanPayload: InternalSendMealPlanPayload, options?: RawAxiosRequestConfig): AxiosPromise<SendMealPlanResponse> {
            return localVarFp.internalSendMealPlanInternalMealplanMealPlanIdSendPost(mealPlanId, internalSendMealPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Update Meal Plan
         * @param {string} mealPlanId 
         * @param {UpdateMealPlanPayload} updateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateMealPlanInternalMealplanMealPlanIdPut(mealPlanId: string, updateMealPlanPayload: UpdateMealPlanPayload, options?: RawAxiosRequestConfig): AxiosPromise<MealPlanResponse> {
            return localVarFp.internalUpdateMealPlanInternalMealplanMealPlanIdPut(mealPlanId, updateMealPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend User Invite
         * @param {ResendUserInvitePayload} resendUserInvitePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInviteAdminUsersResendInvitePost(resendUserInvitePayload: ResendUserInvitePayload, options?: RawAxiosRequestConfig): AxiosPromise<ResendUserInviteResponse> {
            return localVarFp.resendUserInviteAdminUsersResendInvitePost(resendUserInvitePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Meal Plan
         * @param {string} mealPlanId 
         * @param {SendMealPlanPayload} sendMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMealPlanMealplanMealPlanIdSendPost(mealPlanId: string, sendMealPlanPayload: SendMealPlanPayload, options?: RawAxiosRequestConfig): AxiosPromise<SendMealPlanResponse> {
            return localVarFp.sendMealPlanMealplanMealPlanIdSendPost(mealPlanId, sendMealPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Meal Plan
         * @param {string} mealPlanId 
         * @param {UpdateMealPlanPayload} updateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMealPlanMealplanMealPlanIdPut(mealPlanId: string, updateMealPlanPayload: UpdateMealPlanPayload, options?: RawAxiosRequestConfig): AxiosPromise<MealPlanResponse> {
            return localVarFp.updateMealPlanMealplanMealPlanIdPut(mealPlanId, updateMealPlanPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Admin Sync Healthie Emails
     * @param {SyncHealthieEmailsPayload} syncHealthieEmailsPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminSyncHealthieEmailsAdminSyncHealthieEmailsPost(syncHealthieEmailsPayload: SyncHealthieEmailsPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminSyncHealthieEmailsAdminSyncHealthieEmailsPost(syncHealthieEmailsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Admin User
     * @param {CreateAdminUserPayload} createAdminUserPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAdminUserAdminUsersAdminPost(createAdminUserPayload: CreateAdminUserPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createAdminUserAdminUsersAdminPost(createAdminUserPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Provider User
     * @param {CreateProviderUserPayload} createProviderUserPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProviderUserAdminUsersProviderPost(createProviderUserPayload: CreateProviderUserPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createProviderUserAdminUsersProviderPost(createProviderUserPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchCurrentUserUsersMeGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchCurrentUserUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Payouts For Provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchPayoutsForProviderProviderPayoutsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchPayoutsForProviderProviderPayoutsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchProvidersAdminProvidersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchProvidersAdminProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Meal Plan
     * @param {GenerateMealPlanPayload} generateMealPlanPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateMealPlanMealplanPost(generateMealPlanPayload: GenerateMealPlanPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).generateMealPlanMealplanPost(generateMealPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Meal Plan
     * @param {string} mealPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMealPlanInternalMealplanMealPlanIdGet(mealPlanId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMealPlanInternalMealplanMealPlanIdGet(mealPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Meal Plan
     * @param {string} mealPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMealPlanMealplanMealPlanIdGet(mealPlanId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMealPlanMealplanMealPlanIdGet(mealPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Practice Metrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProviderPracticeMetricsProviderPracticeMetricsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProviderPracticeMetricsProviderPracticeMetricsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Authentication Info
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserAuthenticationInfoUsersAuthenticationInfoGet(email: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserAuthenticationInfoUsersAuthenticationInfoGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthCheckHealthGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthCheckHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Generate Meal Plan
     * @param {InternalGenerateMealPlanPayload} internalGenerateMealPlanPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public internalGenerateMealPlanInternalMealplanPost(internalGenerateMealPlanPayload: InternalGenerateMealPlanPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).internalGenerateMealPlanInternalMealplanPost(internalGenerateMealPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Send Meal Plan
     * @param {string} mealPlanId 
     * @param {InternalSendMealPlanPayload} internalSendMealPlanPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public internalSendMealPlanInternalMealplanMealPlanIdSendPost(mealPlanId: string, internalSendMealPlanPayload: InternalSendMealPlanPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).internalSendMealPlanInternalMealplanMealPlanIdSendPost(mealPlanId, internalSendMealPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Update Meal Plan
     * @param {string} mealPlanId 
     * @param {UpdateMealPlanPayload} updateMealPlanPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public internalUpdateMealPlanInternalMealplanMealPlanIdPut(mealPlanId: string, updateMealPlanPayload: UpdateMealPlanPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).internalUpdateMealPlanInternalMealplanMealPlanIdPut(mealPlanId, updateMealPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend User Invite
     * @param {ResendUserInvitePayload} resendUserInvitePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resendUserInviteAdminUsersResendInvitePost(resendUserInvitePayload: ResendUserInvitePayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).resendUserInviteAdminUsersResendInvitePost(resendUserInvitePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Meal Plan
     * @param {string} mealPlanId 
     * @param {SendMealPlanPayload} sendMealPlanPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendMealPlanMealplanMealPlanIdSendPost(mealPlanId: string, sendMealPlanPayload: SendMealPlanPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sendMealPlanMealplanMealPlanIdSendPost(mealPlanId, sendMealPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Meal Plan
     * @param {string} mealPlanId 
     * @param {UpdateMealPlanPayload} updateMealPlanPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateMealPlanMealplanMealPlanIdPut(mealPlanId: string, updateMealPlanPayload: UpdateMealPlanPayload, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateMealPlanMealplanMealPlanIdPut(mealPlanId, updateMealPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Admin Deactivate Provider
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost: async (healthieProviderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthieProviderId' is not null or undefined
            assertParamExists('adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost', 'healthieProviderId', healthieProviderId)
            const localVarPath = `/admin/provider/{healthie_provider_id}/deactivate`
                .replace(`{${"healthie_provider_id"}}`, encodeURIComponent(String(healthieProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin Get Provider Practice Metrics
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet: async (healthieProviderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthieProviderId' is not null or undefined
            assertParamExists('adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet', 'healthieProviderId', healthieProviderId)
            const localVarPath = `/admin/provider/{healthie_provider_id}/practice-metrics`
                .replace(`{${"healthie_provider_id"}}`, encodeURIComponent(String(healthieProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Admin Deactivate Provider
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDeactivatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Admin Get Provider Practice Metrics
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderApi.adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderApiFp(configuration)
    return {
        /**
         * 
         * @summary Admin Deactivate Provider
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId: string, options?: RawAxiosRequestConfig): AxiosPromise<UserDeactivatedResponse> {
            return localVarFp.adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin Get Provider Practice Metrics
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId: string, options?: RawAxiosRequestConfig): AxiosPromise<PracticeMetricsResponse> {
            return localVarFp.adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
    /**
     * 
     * @summary Admin Deactivate Provider
     * @param {string} healthieProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId: string, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).adminDeactivateProviderAdminProviderHealthieProviderIdDeactivatePost(healthieProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin Get Provider Practice Metrics
     * @param {string} healthieProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId: string, options?: RawAxiosRequestConfig) {
        return ProviderApiFp(this.configuration).adminGetProviderPracticeMetricsAdminProviderHealthieProviderIdPracticeMetricsGet(healthieProviderId, options).then((request) => request(this.axios, this.basePath));
    }
}



